
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        type: {
            default: 'blue'
        },
        list: {
            type: Array,
            default: () => []
        },
        label1Alias: {
            default: 'label1'
        },
        label2Alias: {
            default: 'label2'
        }
    }
});
